import './react-pro-sidebar.css';
import React, { useRef } from 'react';
import UserProfile from 'components/layout/user-profile';
import moment from 'moment';
import { Link } from "react-router-dom";
import { Sidebar, sidebarClasses, Menu, MenuItem, menuClasses, SubMenu, useProSidebar, } from 'react-pro-sidebar';
import { getAuth } from "firebase/auth";
import { sortByPosition } from 'components/common/toolbox';
import { useSelector } from 'react-redux';

export default function SideMenu(props) {

    const { collapseSidebar, collapsed } = useProSidebar();
    const P = useSelector(store => store.users?.user_permissions);
    const ref = useRef(null);

    const settings = useSelector((store) => store.settings.settings);
    const assetTypes = useSelector((store) => store.settings.settings_asset_types)?.filter((o) => !o.deleted);
    const selectedType = useSelector((store) => store.assets.asset_type);
    const selectedCustomer = useSelector((store) => store.assets.customer);

    const firebaseUser = getAuth().currentUser;
    const email = firebaseUser?.email;
    const targetAssetTypeId = (selectedType && assetTypes?.find((at) => at?.id == selectedType)) ? selectedType : '0';
    const targetCustomerId = (selectedCustomer) ? selectedCustomer : '0';

    return (

        <div ref={ref} style={{ display: 'flex', height: '100%', position: 'absolute', zIndex: 9999 }}
            onMouseEnter={() => collapseSidebar(false)}
            onMouseLeave={() => collapseSidebar(true)}
        >
            <Sidebar
                width={'240px'}
                style={{ height: '100vh' }}
                defaultCollapsed={true}
                collapsedWidth='80px'
                backgroundColor='#2f4050'
                rootStyles={{
                    [`.${menuClasses.active}`]: {
                        color: '#ffffff',
                        backgroundColor: '#a68d42',
                        // width: '220px',
                    },
                    [`.${menuClasses.button}:hover.${menuClasses.active}`]: {
                        color: '#ffffff',
                        backgroundColor: '#a68d42',
                        // width: '220px',
                    },
                    [`.${menuClasses.button}:hover`]: {
                        color: '#ffffff',
                        backgroundColor: '#bfa34d !important',
                        // width: '220px',
                    },
                    [`.${sidebarClasses.container}`]: {
                        fontSize: '14px',
                        color: '#ababab',
                    },
                    [`.${menuClasses.menuItemRoot}`]: {
                        fontSize: '14px',
                        backgroundColor: '#2f4050',
                    },
                }}
            >
                <div style={{ minHeight: '180px', marginBottom: '20px' }} >
                    {props.userRoles && props.user && email &&
                        <UserProfile container={ref} user={props.user} email={email} userRoles={props.userRoles} collapsed={collapsed} />
                    }
                </div>
                <Menu iconShape="circle" closeOnClick={true}>
                    <MenuItem active={props.location.pathname.startsWith('/' + props.handle + '/dashboard')}
                            icon={<i className="fas fa-tachometer-alt fa-lg"></i>}
                            component={<Link replace onClick={() => collapseSidebar(true)} to={`/${props.handle}/dashboard`} />}
                        >DASHBOARD
                    </MenuItem>

                    {(P?.CUSTOMERS_VIEW) && settings?.customers?.customerAssets &&
                        <MenuItem active={props.location.pathname.startsWith('/' + props.handle + '/customers')}
                            icon={<i className="fas fa-users fa-lg"></i>}
                            component={<Link  replace onClick={() => collapseSidebar(true)} to={`/${props.handle}/customers`} />}
                        >CUSTOMERS
                        </MenuItem>
                    }

                    {(P?.ASSETS_VIEW) &&
                        <MenuItem active={props.location.pathname.startsWith('/' + props.handle + '/assets')}
                            icon={<i className="fas fa-truck fa-lg"></i>}
                            component={<Link replace onClick={() => collapseSidebar(true)} to={`/${props.handle}/assets/${targetCustomerId}/${targetAssetTypeId}`} />}        
                        >ASSETS
                        </MenuItem>
                    }

                    {(P?.STOCK_PARTS_VIEW) &&
                        <MenuItem active={props.location.pathname.startsWith('/' + props.handle + '/stock_parts')}
                            icon={<i className="fas fa-toolbox fa-lg"></i>}
                            component={<Link replace onClick={() => collapseSidebar(true)} to={`/${props.handle}/stock_parts/${targetAssetTypeId}`} />}
                        >INVENTORY
                        </MenuItem>
                    }

                    {(P?.VENDORS_VIEW) &&
                        <MenuItem active={props.location.pathname.startsWith('/' + props.handle + '/vendors')}
                            icon={<i className="fas fa-store fa-lg"></i>}
                            component={<Link replace onClick={() => collapseSidebar(true)} to={`/${props.handle}/vendors`} />}
                        >VENDORS
                        </MenuItem>
                    }
                    {(P?.PURCHASE_ORDERS_VIEW) &&
                        <MenuItem active={props.location.pathname.startsWith('/' + props.handle + '/vendors')}
                            icon={<i className="fas fa-file-alt fa-lg"></i>}
                            component={<Link replace onClick={() => collapseSidebar(true)} to={`/${props.handle}/vendors`} />}
                        >PURCHASE ORDERS
                        </MenuItem>
                    }


                    {/* <MenuItem
                        active={props.location.pathname.startsWith('/' + props.handle + '/purchase_orders')}
                        icon={<i className="fas fa-file-alt fa-lg"></i>}
                        component={<Link replace onClick={() => collapseSidebar(true)} to={'/' + props.handle + '/purchase_orders'} />}
                    >
                        PURCHASE ORDERS
                    </MenuItem> */}

                    {(P?.SCHEDULE_VIEW) &&
                        <MenuItem active={props.location.pathname.startsWith('/' + props.handle + '/schedule')}
                            icon={<i className="far fa-calendar-alt fa-lg"></i>}
                            component={<Link replace onClick={() => collapseSidebar(true)} to={'/' + props.handle + '/schedule/' + moment().format('YYYY-MM-DD')} />}
                        >SCHEDULE
                        </MenuItem>
                    }

                    {(P?.EMPLOYEES_VIEW) &&
                        <MenuItem active={props.location.pathname.startsWith(`/${props.handle}/employees/search`)}
                            icon={<i className="fas fa-id-badge fa-lg" aria-hidden="true"></i>}
                            component={<Link replace onClick={() => collapseSidebar(true)} to={'/' + props.handle + '/employees/search'} />}
                        >EMPLOYEES
                        </MenuItem>
                    }

                    <div className="mb-5"></div>

                    {P?.ARCHIVES_VIEW &&
                        <MenuItem rootStyles={{ position: 'absolute', left: 0, bottom: 100 }}
                            active={props.location.pathname.startsWith('/' + props.handle + '/archive')}
                            icon={<i className="fas fa-archive"></i>}
                            component={<Link onClick={() => collapseSidebar(true)} to={'/' + props.handle + '/archive'} />}
                        >ARCHIVE
                        </MenuItem>
                    }
                    
                    <MenuItem active={props.location.pathname.startsWith('/' + props.handle + '/settings')}
                        rootStyles={{ position: 'absolute', left: 0, bottom: 50 }}
                        icon={<i className="fas fa-cog"></i>}
                        component={<Link onClick={() => collapseSidebar(true)} to={'/' + props.handle + '/settings/company'} />}
                    >SETTINGS
                    </MenuItem>
                </Menu>
                <main style={{ position: 'absolute', left: 10, bottom: 5 }}>
                    <center>
                        {window.build}
                    </center>
                </main>
            </Sidebar>
        </div>
    );
}
