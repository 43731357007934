import * as types from './action-types';
import firebase from 'firebase/compat/app';

const firestore = firebase.firestore();

/*-----------------------------------------------*/
/*  COMPANY
/*-----------------------------------------------*/

export function subCompany(handle) {

	return dispatch => {

		dispatch({ type: types.COMPANY + '_PENDING' });

		const unsubscribe = firestore.collection('_companies').doc(handle).onSnapshot((doc) => {
			const company = { ...doc.data() };

			dispatch({ type: types.COMPANY + '_FULFILLED', data: company, unsubscribe });
		});
	}
}