import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import _ from 'lodash';
import firebase from 'firebase/compat/app';
import moment from 'moment';
import { ROLES } from 'components/common/constants';
import { collection, getCountFromServer, getFirestore, query, where, deleteField } from 'firebase/firestore';

const firestore = firebase.firestore();

/*-----------------------------------------------*/
/*  USERS
/*-----------------------------------------------*/

export function subUser(handle, email, callback) {

	return dispatch => {

		dispatch({ type: types.USER + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/users/users').doc(email).onSnapshot((doc) => {
			var user = { ...doc.data(), id: doc.id };

			firestore.collection(handle + '/contacts/contacts').where('email', '==', email).get().then((snapshot) => {
				let doc = snapshot.docs[0];
				var contact = { ...doc.data(), id: doc.id };
				
				dispatch({ type: types.USER + '_FULFILLED', data: { ...user, email, contact }, unsubscribe });
				if (typeof callback === 'function') callback(user);
			});
		});
	};
}
export function subUserPermissions(handle, email, roleId) {

	return async dispatch => {

		dispatch({ type: types.USER_PERMISSIONS + '_PENDING' });

		if (email) {
			var unsubscribe = firestore.collection(handle + '/users/users').doc(email).onSnapshot(async (doc) => {

				const userRoles = doc.data().roles;
				const userId = doc.data().profileId;
				var settingsDoc = await firestore.collection(handle).doc('settings').get();
				const permissionsData = settingsDoc.data()?.permissions ?? {};

				var permissions = { OWNER: userRoles.includes(ROLES.OWNER.id), ADMINISTRATOR: userRoles.includes(ROLES.ADMINISTRATOR.id) };

				Object.keys(permissionsData).forEach((key) => {
					permissions[key] = (permissionsData[key].excluded?.find((exc) => exc.value == userId)) 
						? false
						: (permissionsData[key].included?.find((inc) => inc.value == userId)) 
							? true
							: !!(permissionsData[key].roles?.find((role) => userRoles.includes(role)));
				});

				dispatch({ type: types.USER_PERMISSIONS + '_FULFILLED', data: permissions, unsubscribe });
			});

			var unsubscribe = firestore.collection(handle).doc('settings').onSnapshot(async (doc) => {

				var userDoc = await firestore.collection(handle + '/users/users').doc(email).get();
				const userRoles = userDoc.data().roles;
				const userId = userDoc.data().profileId;
				const permissionsData = doc.data()?.permissions ?? {};

				var permissions = { OWNER: userRoles.includes(ROLES.OWNER.id), ADMINISTRATOR: userRoles.includes(ROLES.ADMINISTRATOR.id) };

				Object.keys(permissionsData).forEach((key) => {
					permissions[key] = (permissionsData[key].excluded?.find((exc) => exc.value == userId)) 
						? false
						: (permissionsData[key].included?.find((inc) => inc.value == userId)) 
							? true
							: !!(permissionsData[key].roles?.find((role) => userRoles.includes(role)));
				});

				dispatch({ type: types.USER_PERMISSIONS + '_FULFILLED', data: permissions, unsubscribe });
			});

		} else if (roleId) {
			
			var settingsDoc = await firestore.collection(handle).doc('settings').get();
			const permissionsData = settingsDoc.data().permissions ?? {};

			var permissions = { OWNER: roleId == ROLES.OWNER.id, ADMINISTRATOR: roleId == ROLES.ADMINISTRATOR.id };

			Object.keys(permissionsData).forEach((key) => {
				permissions[key] = !!(permissionsData[key].roles?.find((role) => role == roleId));
			});

			dispatch({ type: types.USER_PERMISSIONS + '_FULFILLED', data: permissions, unsubscribe });
		}
	};
}
export function updateUser(handle, email, profileId, user, callback) {

	return async dispatch => {

		dispatch({ type: types.USER + '_SAVE_PENDING' });

		var batch = firestore.batch();

		const userRef = firestore.collection(handle + '/users/users');
		const profileRef = firestore.collection(handle + '/profiles/profiles');

		if (email) batch.set(userRef.doc(email), { ...user }, { merge: true });
		if (profileId) batch.set(profileRef.doc(profileId), { modified: new Date(),
			...(user.roles) ? [{ roles: user.roles }] : [{}], 	
		}, { merge: true });

		batch.commit().then(() => {
			window.toastr.success('The User record has been successfully saved/updated', 'User Saved!');
			dispatch({ type: types.USER + '_SAVE_FULFILLED' });
			if (typeof callback === 'function') callback(email);
		}).catch((error) => {
			toolbox.process_error(error, 'User NOT Saved!');
		});
	};
}
export function deleteUser(handle, email, callback) {

	return async dispatch => {

		dispatch({ type: types.USER + '_SAVE_PENDING' });

		firestore.collection(handle + '/users/users').doc(email).delete().then(() => {
			window.toastr.success('The User record has been permanently deleted', 'User Saved!');
			dispatch({ type: types.USER + '_SAVE_FULFILLED' });
			if (typeof callback === 'function') callback(email);
		}).catch((error) => {
			toolbox.process_error(error, 'User NOT Saved!');
		});
	};
}
// export function subUserLocations(handle, email, callback) {

// 	return dispatch => {

// 		dispatch({ type: types.USER_LOCATIONS + '_PENDING' });

// 		var unsubscribe = firestore.collection(handle + '/users/users/' + email + '/locations').get().then((querySnapshot) => {

// 			var locations = [];
// 			querySnapshot.forEach((doc) => {
// 				locations.push({ ...doc.data(), id: doc.id });
// 			});

// 			dispatch({ type: types.USER_LOCATIONS + '_FULFILLED', data: locations, unsubscribe });
// 			if (typeof callback === 'function') callback();
// 		});
// 	};
// }

/*-----------------------------------------------*/
/*  TIME-CLOCK
/*-----------------------------------------------*/

export function subTimeClock(handle, userId) {

	return async dispatch => {

		dispatch({ type: types.TIME_CLOCK + '_PENDING' });

		const id = moment().format('YY-MM-DD'); // '24-19-24'
		var unsubscribe = firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).onSnapshot((doc) => {
			if (doc.exists) {
				var time_clock_data = { ...doc.data(), id: doc.id }

				var clockedIn = false;
				var secondsAccumulated = 1;
				var lastTimeIn = null;
				var lastTimeOut = null;

				time_clock_data.entries.forEach((entry, index) => {
					if (entry['time-out']) {
						secondsAccumulated += moment(entry['time-out'].toDate()).diff(moment(entry['time-in'].toDate()), 'seconds');
						lastTimeOut = entry['time-out'];
					} else {
						clockedIn = true;
						lastTimeIn = entry['time-in'];
					}
				});
			}

			var time_clock = { clockedIn, secondsAccumulated, lastTimeIn, lastTimeOut }

			dispatch({ type: types.TIME_CLOCK + '_FULFILLED', data: time_clock, unsubscribe });
		});
	};
}
export function getTimeClockRaw(handle, userId, startdate, enddate) {

	return async dispatch => {

		dispatch({ type: types.TIME_ENTRIES + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/users/users/' + userId + '/time-clock')
			.where('date', '>=', moment(startdate, 'X').toDate())
			.where('date', '<=', moment(enddate, 'X').toDate())
			.orderBy('date', 'desc')
			.onSnapshot((querySnapshot) => {
				var time_clock = [];
				querySnapshot.forEach((doc) => {
					time_clock.push({ ...doc.data(), id: doc.id });
				});

				dispatch({ type: types.TIME_ENTRIES + '_FULFILLED', data: time_clock, unsubscribe });
			}
		);
	};
}
export function getTimeSheet(handle, dateRange) {

	return async dispatch => {

		dispatch({ type: types.TIME_SHEET + '_PENDING' });

		// Time Clock
		const timeSnapshot = await firestore.collectionGroup('time-clock').where('handle', '==', handle).where('date', '>=', dateRange[0]).where('date', '<', dateRange[1]).get();	
		var entries = [];
		timeSnapshot.forEach((doc) => { entries.push({ ...doc.data(), id: doc.ref.parent.parent.id }); });
		const timeClock = Object.groupBy(entries, ({ id }) => id);

		// Checked In
		const checkedInSnapshot = await firestore.collectionGroup('check-in').where('handle', '==', handle).where('date', '>=', dateRange[0]).where('date', '<', dateRange[1]).get();
		var entries = [];
		checkedInSnapshot.forEach((doc) => { entries.push({ ...doc.data(), id: doc.ref.parent.parent.id }); });
		const checkedIn = Object.groupBy(entries, ({ userId }) => userId);

		const values = [timeClock, checkedIn]
		dispatch({ type: types.TIME_SHEET + '_FULFILLED', data: values });
	};
}
export function clockIn(handle, userId) {

	return dispatch => {

		dispatch({ type: types.TIME_CLOCK + '_SAVE_PENDING' });

		const id = moment().format('YY-MM-DD');
		const docRef = firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id);

		docRef.get().then(async(doc) => {
			var entries = (doc.exists) ? doc.data().entries : [];
			if (!entries.length || entries[entries.length-1]['time-out']) {
				entries.push({ 'time-in': new Date() });
				await docRef.set({ handle, date: moment().startOf('day').toDate(), entries });
				window.toastr.info('You have been successfully Clocked In', 'Clocked In!');
			} else {
				// already Clocked in
			}

			dispatch({ type: types.TIME_CLOCK + '_SAVE_FULFILLED', data: null });
		});
	};
}
export function clockOut(handle, userId) {

	return async dispatch => {

		dispatch({ type: types.TIME_CLOCK + '_SAVE_PENDING' });

		const id = moment().format('YY-MM-DD');
		const docRef = firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id);

		docRef.get().then(async(doc) => {
			if (doc.exists) {
				var entries = doc.data().entries;
				entries[entries.length - 1]['time-out'] = new Date();
				await docRef.set({ entries }, { merge: true });
				window.toastr.info('You have been successfully Clocked Out', 'Clocked Out!');
			}

			dispatch({ type: types.TIME_CLOCK + '_SAVE_FULFILLED', data: null });
		});
	};
}
export function saveNewClockEntry(handle, userId, newEntry) {

	return dispatch => {

		dispatch({ type: types.TIME_CLOCK + '_SAVE_PENDING' });

		const id = moment(newEntry.date).format('YY-MM-DD');
		const docRef = firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id);

		docRef.get().then(async(doc) => {
			var entries = (doc.exists) ? doc.data().entries : [];
			entries.push({ 
				['time-in']: (newEntry.timeIn) ? moment(id + ' ' + newEntry.timeIn, 'YY-MM-DD h:mm a').toDate() : '',
				['time-out']: (newEntry.timeOut) ? moment(id + ' ' + newEntry.timeOut, 'YY-MM-DD h:mm a').toDate() : '',
			});

			let record = { 
				date: moment(newEntry.date).toDate(),
				entries,
				handle: handle,
			};
			await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).set(record, { merge: true });
	
			dispatch({ type: types.TIME_CLOCK + '_SAVE_FULFILLED', data: null });
		});
	};
}
export function deleteClockEntry(handle, userId, entry, callback) {

	return async dispatch => {

		dispatch({ type: types.TIME_CLOCK + '_SAVE_PENDING' });

		const id = moment(entry.date.seconds, 'X').format('YY-MM-DD');
		const doc = await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).get();
		var day = { ...doc.data(), id: doc.id };

		// remove existing day entry
		var entries = [];
		day.entries.forEach((db_entry) => {

			let dbIn = (moment(db_entry['time-in']?.seconds, 'X').isValid()) ? moment(db_entry['time-in']?.seconds, 'X').format('h:mm a') : '';
			let dbOut = (moment(db_entry['time-out']?.seconds, 'X').isValid()) ? moment(db_entry['time-out']?.seconds, 'X').format('h:mm a') : '';

			if ((entry.timeIn != dbIn) || (entry.timeOut != dbOut)) {
				entries.push({ ['time-in']: db_entry['time-in'], ['time-out']: db_entry['time-out'] })
			}
		});
		await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).set({ entries }, { merge: true });

		dispatch({ type: types.TIME_CLOCK + '_SAVE_FULFILLED' });
	};
}
export function updateClockEntry(handle, userId, field, entry, newValue, callback) {

	return async dispatch => {

		dispatch({ type: types.TIME_CLOCK + '_SAVE_PENDING' });

		if (field == 'date') {		
			const id = moment(entry.date).format('YY-MM-DD');
			const doc = await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).get();
			var day = { ...doc.data(), id: doc.id };
	
			// remove existing day entry
			var entries = [];
			day.entries.forEach((db_entry) => {
	
				let dbIn = (moment(db_entry['time-in']?.seconds, 'X').isValid()) ? moment(db_entry['time-in']?.seconds, 'X').format('h:mm a') : '';
				let dbOut = (moment(db_entry['time-out']?.seconds, 'X').isValid()) ? moment(db_entry['time-out']?.seconds, 'X').format('h:mm a') : '';
	
				if ((entry.timeIn != dbIn) || (entry.timeOut != dbOut)) {
					entries.push({ ['time-in']: db_entry['time-in'], ['time-out']: db_entry['time-out'] })
				}
			});
			await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).set({ entries }, { merge: true });
			
			// add new day entry
			const id2 = moment(newValue).format('YY-MM-DD');
			const doc2 = await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id2).get();
			if (doc2.exists) {
				var day2 = { ...doc2.data(), id: doc2.id };
				let entries2 = [ ...day2.entries, { 
					['time-in']: (entry.timeIn) ? moment(id2 + ' ' + entry.timeIn, 'YY-MM-DD h:mm a').toDate() : '',
					['time-out']: (entry.timeOut) ? moment(id2 + ' ' + entry.timeOut, 'YY-MM-DD h:mm a').toDate() : '',
				}];

				await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id2).set({ entries: entries2 }, { merge: true });

			} else {
				let record = { 
					date: moment(newValue).toDate(),
					entries: [{ 
						['time-in']: (entry.timeIn) ? moment(id2 + ' ' + entry.timeIn, 'YY-MM-DD h:mm a').toDate() : '',
						['time-out']: (entry.timeOut) ? moment(id2 + ' ' + entry.timeOut, 'YY-MM-DD h:mm a').toDate() : '',
					}],
					handle: handle,
				};
				await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id2).set(record, { merge: true });
			}

		} else {
			const id = moment(entry.date.seconds, 'X').format('YY-MM-DD');
			const doc = await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).get();
			var day = { ...doc.data(), id: doc.id };
	
			// Cycle through day entries and update changed
			var entries = [];
			day.entries.forEach((db_entry) => {
	
				let dbIn = (moment(db_entry['time-in']?.seconds, 'X').isValid()) ? moment(db_entry['time-in']?.seconds, 'X').format('h:mm a') : '';
				let dbOut = (moment(db_entry['time-out']?.seconds, 'X').isValid()) ? moment(db_entry['time-out']?.seconds, 'X').format('h:mm a') : '';
	
				if ((entry.timeIn == dbIn) && (entry.timeOut == dbOut)) {
	
					let timeIn = (field == 'timeIn') ? newValue : entry.timeIn;
					let timeOut = (field == 'timeOut') ? newValue : entry.timeOut;
					
					entries.push({
						['time-in']: (timeIn) ? moment(id + ' ' + ((field == 'timeIn') ? newValue : entry.timeIn), 'YY-MM-DD h:mm a').toDate() : '',
						['time-out']: (timeOut) ? moment(id + ' ' + ((field == 'timeOut') ? newValue : entry.timeOut), 'YY-MM-DD h:mm a').toDate() : '',
					})
				} else {
					entries.push({ ['time-in']: db_entry['time-in'], ['time-out']: db_entry['time-out'] })
				}
			});
			await firestore.collection(handle + '/users/users/' + userId + '/time-clock').doc(id).set({ entries }, { merge: true });
		}
		window.toastr.success('Your time entries have been successfully Modified', 'Time Clock Updated!');

		dispatch({ type: types.TIME_CLOCK + '_SAVE_FULFILLED' });
	};
}

/*-----------------------------------------------*/
/*  SERVICE ITEMS
/*-----------------------------------------------*/

export function addItemToRecent(handle, item, userId) {

	return async dispatch => {

		dispatch({ type: types.USER + '_SAVE_PENDING' });

		firestore.collection(handle + '/users/users/' + userId + '/items').doc('items').set({ recent: { [item.id]: new Date() } }, { merge: true }).then(() => {
			dispatch({ type: types.USER + '_SAVE_FULFILLED' });
		}).catch((error) => {
			toolbox.process_error(error, 'Unable to add item to recents!');
		});
	};
}
export function trimRecent(handle, recentArray, typeId, userId) {

	return async dispatch => {

		dispatch({ type: types.USER + '_SAVE_PENDING' });

		var batch = firestore.batch();
		var deleteValues = recentArray.slice(50);
		deleteValues.forEach((value) => {
			batch.set(firestore.collection(handle + '/users/users/' + userId + '/service-items').doc(typeId.toString()), { recent: { [value.id]: deleteField() } }, { merge: true });
		});

		batch.commit().then(() => {
			dispatch({ type: types.USER + '_SAVE_FULFILLED' });
		}).catch((error) => {
			toolbox.process_error(error, 'Unable to add item to recents!');
		});
	};
}
export function subUserRecentItems(handle, email) {

	return dispatch => {

		dispatch({ type: types.USER_RECENT_ITEMS + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/users/users/' + email + '/items').onSnapshot((querySnapshot) => {

			var recentItems = {};
			querySnapshot.forEach((doc) => {
				recentItems[doc.id] = doc.data();
			});

			dispatch({ type: types.USER_RECENT_ITEMS + '_FULFILLED', data: recentItems, unsubscribe });
		});
	};
}